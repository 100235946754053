import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { WarningModal } from "../../components/Modal";
import Select from "react-select";
import {
  REQByCodiCrt,
  REQFundCode,
  REQRisk,
  REQOccu,
  REQIncome
  // CleanUpByCodiEdt
} from "./FundRecCodi_redux/actions";
import {
  aFunRecCodiCode,
  aInvestObj
} from "../../utils/MockAPI";
import Images from "../../utils/styles/images";
function FundRecCodiCrt(props) {
  const {
    REQFundCode,
    REQRisk,
    REQOccu,
    REQIncome,

    FundCode,
    Risk,
    Occu,
    Income,

    REQByCodiCrt,
    history
  } = props;

  const GETxByCodi = () => {
    REQFundCode();
    REQRisk();
    REQOccu();
    REQIncome();
  };

  useEffect(() => {
    
    GETxByCodi();
  }, []);

  const [oSelFundCodeOp, SETxSelFundCodeOp] = useState([]);
  const SETxOnChgFundCodeSelect = e => {
    SETxSelFundCodeOp(Array.isArray(e) ? e.map(x => x) : []);
  };

  const oNeedAuth = JSON.parse(localStorage.getItem("NeedAuth"));
  const [oShwEdtAlr, SETxShwEdtAlr] = useState(false);
  // ------------------Init Condition---------------
  // ใช้Send API
  const [aCondition, SETaCondition] = useState([]);
  const [aCodiFundRec, SETaCodiFundRec] = useState([]);

  // ---------------Text---------------------
  const [oVal, SEToVal] = useState({
    otbRuleName1: ""
  });
  const SETxOnChgTxt = name => {
    return ({ target: { value } }) => {
      SEToVal(oldValues => ({ ...oldValues, [name]: value }));
    };
  };
  // ---------------Select option-------------
  const oFunCodeOp = FundCode.map(d => ({
    value: d.fund_no,
    label: d.fund_code
  }));

  const fundCodeStringValue = FundCode.map(d => ({
    value: d.fund_no,
    label: d.fund_code
  }));

  const oOccupaOp = Occu.map(d => ({
    value: d.code,
    label: d.name
  }));
  const oIncomeOp = Income.map(d => ({
    value: d.code,
    label: d.name
  }));
  const oRiskOp = Risk.map(d => ({
    value: d.id.toString(),
    label: d.risk_level
  }));
  const oInvestObjOp = aInvestObj.map(d => ({
    value: d.id,
    label: d.NameTH
  }));

  const [aRecCodiCodeOp, SETaRecCodiCodeOp] = useState(
    aFunRecCodiCode.data.map(d => ({
      value: d.ID,
      label: d.Condition
    }))
  );
  const [tSelFundRecCodiCodeOp, SETtSelFundRecCodiCodeOp] = useState({
    value: aRecCodiCodeOp.length === 0 ? "" : aRecCodiCodeOp[0].value,
    label: aRecCodiCodeOp.length === 0 ? "" : aRecCodiCodeOp[0].label
  });
  // ----------------Selection Funcode--------
  const [oSelFundCodeMainOp, SETxSelFundCodeMainOp] = useState([]);
  
  const UPDxFunCodeOnChgSelect = evt => {
    if (Array.isArray(evt) === true) {
      SETxSelFundCodeMainOp(Array.isArray(evt) ? evt.map(x => x) : []);
      // -----------------FunCode------------
      SETaCodiFundRec(
        evt.map(x => ({
          fund_code: x.label,
          fund_no: x.value
        }))
      );
    } else {
      SETxSelFundCodeMainOp([]);
      SETaCodiFundRec([]);
    }
  };

  const SETxOnChgFundCodiSelect = e => {
    SETtSelFundRecCodiCodeOp({ value: e.value, label: e.label });
    SETxSelFundCodeOp([]);
  };

  // ---------------Radio-------------
  const [oSta, SETxSta] = useState("Unpublish");
  const [oUsr, SETxUsr] = useState("All");
  const [oCodiShw, SETbCodiShw] = useState("AND");
  function SETxOnChgRaioAdd(e) {
    const tNameRadio = e.target.getAttribute("name");
    switch (tNameRadio) {
      case "orbSta1":
        SETxSta(e.target.value);
        break;
      case "orbUsr1":
        SETxUsr(e.target.value);
        break;
      case "ordCodiShw":
        SETbCodiShw(e.target.value);
        break;
      default:
        break;
    }
  }
  console.log("acodi", aCodiFundRec);
  // ---------------Event------------
  function CRTxCodiAdd() {
    if (
      tSelFundRecCodiCodeOp.value === "" ||
      tSelFundRecCodiCodeOp.value === undefined
    ) {
      return;
    }
    let tId = tSelFundRecCodiCodeOp.value;
    let tRange1 = null;
    let tRange2 = null;
    let tLine1 = null;
    let tLine2 = null;
    let oSelOp = [];
    switch (tSelFundRecCodiCodeOp.value) {
      case "C001":
        tRange1 = document.getElementsByName("otbLine1_" + tId + "")[0].value;
        tRange2 = document.getElementsByName("otbLine11_" + tId + "")[0].value;
        break;
      // Rage
      case "C008": 
        tRange1 = document.getElementsByName("otbLine1_" + tId + "")[0].value;
        tRange2 = document.getElementsByName("otbLine11_" + tId + "")[0].value;
        break;
      case "C002":
        const selectedCondition002 = oCodiShw; 
        
          oSelOp = oSelFundCodeOp.map(x => ({
            fund_code: x.label,
            fund_no: x.value
        }));
          tLine2 = selectedCondition002;
        break;
      // selection & radio
      case "C003":
        oSelOp = oSelFundCodeOp.map(x => ({
          IDofFund: "",
          fund_code: x.label,
          fund_no: x.value
        }));
        tLine2 = oCodiShw;
        break;
      case "C004":
        oSelOp = oSelFundCodeOp.map(x => ({
          name: x.label,
          code: x.value
      }));
      break;
      case "C005":
        oSelOp = oSelFundCodeOp.map(x => ({
          name: x.label,
          code: x.value
      }));
      break;
      case "C006":
        oSelOp = oSelFundCodeOp.map(x => ({
          name: x.label,
          code: x.value
      }));
      break;
      // selection only
      case "C009": 
        oSelOp = oSelFundCodeOp.map(x => ({
          name: x.label,
          code: x.value
        }));
        break;
      default:
        break;
    }
    

    // ------Set State --------

    SETaCondition(arr => [
      ...arr,
      {
        SeqCodiSelect: arr.length + 1,
        IDofRule: "",
        TypeCodi: tSelFundRecCodiCodeOp.value,
        Line1: tLine1,
        Line2: tLine2,
        Range1: tRange1,
        Range2: tRange2,
        SelOp: oSelOp
      }
    ]);
    // -----------------Remove Select ที่เลือกไป--------------------------------------
    let aFlt = aRecCodiCodeOp.filter(c => c.value !== tId);
    SETaRecCodiCodeOp(aFlt);
    // ----------Set Select--------------
    SETtSelFundRecCodiCodeOp({
      value: aFlt.length === 0 ? "" : aFlt[0].value,
      label: aFlt.length === 0 ? "" : aFlt[0].label
    });
    SETxSelFundCodeOp([]);

    
  }
  const UPDxOnChgTxt = ptIndex => e => {
    const aClonedData = [...aCondition];
    aClonedData[ptIndex][e.target.name] = e.target.value;
    SETaCondition(aClonedData);
  };
  const UPDxOnChgSelect = ptTypeCodi => e => {
    let aNewArr = aCondition.map((x,) => {
      if (x.TypeCodi === ptTypeCodi) {
        let aOld;
        let tLabelSel;
        if (e === null) {
          aOld = { ...x, SelOp: [] };
        } else {
          switch (ptTypeCodi) {
            case "C002":
            case "C003":
              tLabelSel = e.map(x => ({
                IDofFund: "",
                fund_code: x.label,
                fund_no: x.value
              }));
              aOld = { ...x, SelOp: tLabelSel };
              break;
            default:
              tLabelSel = e.map(x => ({ name: x.label, code: x.value }));
              aOld = { ...x, SelOp: tLabelSel };
              break;
          }
        }

        return aOld;
      } else {
        return x;
      }
    });
    SETaCondition(aNewArr);
  };
  const UPDxOnChgRadio = (index, ptTypeCodi) => e => {
    let aNewArr = aCondition.map((x) => {
      if (x.TypeCodi === ptTypeCodi) {
        let a;
        if (e.target.value === "OR") {
          document.getElementsByName(x.TypeCodi + "_AND")[0].checked = false;
        } else {
          document.getElementsByName(x.TypeCodi + "_OR")[0].checked = false;
        }

        a = { ...x, Line2: e.target.value };
        return a;
      } else {
        return x;
      }
    });
    alert("2.UPDxOnChgRadio=" + JSON.stringify(aNewArr));
    SETaCondition(aNewArr);
  };
  const DELxCodi = ptCodiId => () => {
    SETaCondition(aCondition.filter(item => item.TypeCodi !== ptCodiId));

    let tFundTag = aFunRecCodiCode.data.filter(x => x.ID === ptCodiId);
    SETaRecCodiCodeOp(arr => [
      ...arr,
      {
        value: ptCodiId,
        label: tFundTag[0].Condition
      }
    ]);

    SETtSelFundRecCodiCodeOp({
      value: ptCodiId,
      label: tFundTag[0].Condition
    });
  };
  const GENxSelect = () => {
    switch (tSelFundRecCodiCodeOp.value) {
      case "C001":
        return (
          <div
            className="labelInput"
            style={{ paddingTop: 20, paddingBottom: 10 }}
          >
            <span style={{ width: 204, fontFamily: "Lato", color: "#000000" }}>
              {tSelFundRecCodiCodeOp.label}
            </span>
            <input
              className="fundrec-textInput-option"
              maxLength="2"
              name="otbLine1_C001"
              placeholder=""
              onKeyPress={e => {
                if (!/[0-9\b]+$/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              style={{ width: 100 }}
            />
            <span style={{ marginLeft: 10, marginRight: 10 }}>-</span>
            <input
              className="fundrec-textInput-option"
              maxLength="2"
              name="otbLine11_C001"
              placeholder=""
              onKeyPress={e => {
                if (!/[0-9\b]+$/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              style={{ width: 100 }}
            />
          </div>
        );
      case "C002":
        return (
          <div>
            <div
              className="labelInput"
              style={{ paddingTop: 20, paddingBottom: 20 }}
            >
              <span
                style={{ width: 204, fontFamily: "Lato", color: "#000000" }}
              >
                {tSelFundRecCodiCodeOp.label}
              </span>
              <div style={{ width: 599 }}>
                {
                  <Select
                    isMulti
                    name="otbLine1_C002"
                    options={fundCodeStringValue}
                    maxMenuHeight={150}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Tag Fund"
                    onChange={SETxOnChgFundCodeSelect}
                    value={oSelFundCodeOp}
                  />
                }
              </div>
            </div>
            <div className="labelInput" style={{ paddingBottom: 10 }}>
              <span
                style={{
                  paddingRight: 171,
                  fontFamily: "Lato",
                  color: "#000000"
                }}
              >
                Status
              </span>
              <input
                name="ordCodiShw"
                type="radio"
                value="AND"
                checked={oCodiShw === "AND"}
                onChange={SETxOnChgRaioAdd}
              />{" "}
              <span style={{ marginRight: 20, marginLeft: 5 }}>
                ตกเงื่อนไขทั้งหมดตามที่ใส่มา (AND)
              </span>
              <input
                name="ordCodiShw"
                type="radio"
                value="OR"
                checked={oCodiShw === "OR"}
                onChange={SETxOnChgRaioAdd}
              />{" "}
              <span style={{ marginLeft: 5 }}>
                ตกเงื่อนไขกองใดกองหนึ่ง (OR)
              </span>
            </div>
          </div>
        );
      case "C003":
        return (
          <div>
            <div
              className="labelInput"
              style={{ paddingTop: 20, paddingBottom: 20 }}
            >
              <span
                style={{ width: 204, fontFamily: "Lato", color: "#000000" }}
              >
                {tSelFundRecCodiCodeOp.label}
              </span>
              <div style={{ width: 599 }}>
                <Select
                  isMulti
                  name="otbLine1_C003"
                  options={fundCodeStringValue}
                  maxMenuHeight={150}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Select Tag Fund"
                  onChange={SETxOnChgFundCodeSelect}
                  value={oSelFundCodeOp}
                />
              </div>
            </div>
            <div className="labelInput" style={{ paddingBottom: 10 }}>
              <span
                style={{
                  paddingRight: 171,
                  fontFamily: "Lato",
                  color: "#000000"
                }}
              >
                Status
              </span>
              <input
                name="ordCodiShw"
                type="radio"
                value="AND"
                checked={oCodiShw === "AND"}
                onChange={SETxOnChgRaioAdd}
              />{" "}
              <span style={{ marginRight: 20, marginLeft: 5 }}>
                ตกเงื่อนไขทั้งหมดตามที่ใส่มา (AND)
              </span>
              <input
                name="ordCodiShw"
                type="radio"
                value="OR"
                checked={oCodiShw === "OR"}
                onChange={SETxOnChgRaioAdd}
              />{" "}
              <span style={{ marginLeft: 5 }}>
                ตกเงื่อนไขกองใดกองหนึ่ง (OR)
              </span>
            </div>
          </div>
        );
      case "C004":
        return (
          <div>
            <div
              className="labelInput"
              style={{ paddingTop: 20, paddingBottom: 20 }}
            >
              <span
                style={{ width: 204, fontFamily: "Lato", color: "#000000" }}
              >
                {tSelFundRecCodiCodeOp.label}
              </span>
              <div style={{ width: 599 }}>
                <Select
                  isMulti
                  name="otbLine1_C004"
                  options={oOccupaOp}
                  maxMenuHeight={150}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Select Occupation"
                  onChange={SETxOnChgFundCodeSelect}
                  value={oSelFundCodeOp}
                />
              </div>
            </div>
          </div>
        );
      case "C005":
        return (
          <div>
            <div
              className="labelInput"
              style={{ paddingTop: 20, paddingBottom: 20 }}
            >
              <span
                style={{ width: 204, fontFamily: "Lato", color: "#000000" }}
              >
                {tSelFundRecCodiCodeOp.label}
              </span>
              <div style={{ width: 599 }}>
                <Select
                  isMulti
                  name="otbLine1_C005"
                  options={oIncomeOp}
                  maxMenuHeight={150}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Select Income"
                  onChange={SETxOnChgFundCodeSelect}
                  value={oSelFundCodeOp}
                />
              </div>
            </div>
          </div>
        );
      case "C006":
        return (
          <div>
            <div
              className="labelInput"
              style={{ paddingTop: 20, paddingBottom: 20 }}
            >
              <span
                style={{ width: 204, fontFamily: "Lato", color: "#000000" }}
              >
                {tSelFundRecCodiCodeOp.label}
              </span>
              <div style={{ width: 599 }}>
                <Select
                  isMulti
                  name="otbLine1_C006"
                  options={oRiskOp}
                  maxMenuHeight={150}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Select Tag Fund"
                  onChange={SETxOnChgFundCodeSelect}
                  value={oSelFundCodeOp}
                />
              </div>
            </div>
          </div>
        );
      case "C007":
        return (
          <div
            className="labelInput"
            style={{ paddingTop: 20, paddingBottom: 10 }}
          >
            <span style={{ width: 204, fontFamily: "Lato", color: "#000000" }}>
              {tSelFundRecCodiCodeOp.label}
            </span>
          </div>
        );
      case "C008":
        return (
          <div
            className="labelInput"
            style={{ paddingTop: 20, paddingBottom: 10 }}
          >
            <span style={{ width: 204, fontFamily: "Lato", color: "#000000" }}>
              {tSelFundRecCodiCodeOp.label}
            </span>
            <input
              className="fundrec-textInput-option"
              maxLength="20"
              name="otbLine1_C008"
              placeholder=""
              onKeyPress={e => {
                if (!/[0-9\b]+$/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              style={{ width: 100 }}
            />
            <span style={{ marginLeft: 10, marginRight: 10 }}>-</span>
            <input
              className="fundrec-textInput-option"
              maxLength="20"
              name="otbLine11_C008"
              placeholder=""
              onKeyPress={e => {
                if (!/[0-9\b]+$/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              style={{ width: 100 }}
            />
          </div>
        );
      case "C009":
        return (
          <div>
            <div
              className="labelInput"
              style={{ paddingTop: 20, paddingBottom: 20 }}
            >
              <span
                style={{ width: 204, fontFamily: "Lato", color: "#000000" }}
              >
                {tSelFundRecCodiCodeOp.label}
              </span>
              <div style={{ width: 599 }}>
                <Select
                  isMulti
                  name="otbLine1_C006"
                  options={oInvestObjOp}
                  maxMenuHeight={150}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Select Tag Fund"
                  onChange={SETxOnChgFundCodeSelect}
                  value={oSelFundCodeOp}
                />
              </div>
            </div>
          </div>
        );
      default:
        return <div />;
    }
  };
  const FINDxNameCombobox = ptId => {
    var aCodi = aFunRecCodiCode.data.filter(x => x.ID === ptId);
    return aCodi[0].Condition;
  };
  const GENxCodi = (poCondi, ptIndex) => {
    switch (poCondi.TypeCodi) {
      case "C001":
        return (
          <div className="labelInput">
            <span style={{ width: 204, fontFamily: "Lato", color: "#000000" }}>
              ช่วงอายุ
            </span>
            <input
              className="fundrec-textInput-option"
              maxLength="2"
              value={poCondi.Range1}
              name="Range1"
              placeholder=""
              onKeyPress={e => {
                if (!/[0-9\b]+$/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              onChange={UPDxOnChgTxt(ptIndex)}
              style={{ width: 100 }}
            />
            <span style={{ marginLeft: 10, marginRight: 10 }}>-</span>
            <input
              className="fundrec-textInput-option"
              maxLength="2"
              value={poCondi.Range2}
              name="Range2"
              placeholder=""
              onKeyPress={e => {
                if (!/[0-9\b]+$/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              onChange={UPDxOnChgTxt(ptIndex)}
              style={{ width: 100 }}
            />
          </div>
        );
      case "C002":
        return (
          <div>
            <div
              className="labelInput"
              style={{ paddingTop: 20, paddingBottom: 20 }}
            >
              <span
                style={{ width: 204, fontFamily: "Lato", color: "#000000" }}
              >
                กองทุนที่มีใน Port ลูกค้า
              </span>
              <div style={{ width: 599 }}>
                {
                  <Select
                    defaultValue={poCondi.SelOp !== null
                      ? fundCodeStringValue.filter(a =>
                          poCondi.SelOp.some(x => a.value === x.fund_no)
                        )
                      : []}
                    isMulti
                    options={fundCodeStringValue}
                    maxMenuHeight={150}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Tag Fund"
                    onChange={UPDxOnChgSelect(poCondi.TypeCodi)}
                  />
                }
              </div>
            </div>

            <div className="labelInput" style={{ paddingBottom: 10 }}>
              <span
                style={{
                  paddingRight: 171,
                  fontFamily: "Lato",
                  color: "#000000"
                }}
              >
                Status
              </span>
              <input
                name={poCondi.TypeCodi + "_AND"}
                type="radio"
                value="AND"
                defaultChecked={poCondi.Line2 === "AND"}
                onChange={UPDxOnChgRadio(ptIndex, poCondi.TypeCodi)}
              />{" "}
              <span style={{ marginRight: 20, marginLeft: 5 }}>
                ตกเงื่อนไขทั้งหมดตามที่ใส่มา (AND)
              </span>
              <input
                name={poCondi.TypeCodi + "_OR"}
                type="radio"
                value="OR"
                defaultChecked={poCondi.Line2 === "OR"}
                onChange={UPDxOnChgRadio(ptIndex, poCondi.TypeCodi)}
              />{" "}
              <span style={{ marginLeft: 5 }}>
                ตกเงื่อนไขกองใดกองหนึ่ง (OR)
              </span>
            </div>
          </div>
        );
      case "C003":
        return (
          <div>
            <div
              className="labelInput"
              style={{ paddingTop: 20, paddingBottom: 20 }}
            >
              <span
                style={{ width: 204, fontFamily: "Lato", color: "#000000" }}
              >
                กองทุนที่ไม่มีใน Port ลูกค้า
              </span>
              <div style={{ width: 599 }}>
                {
                  <Select
                    defaultValue={fundCodeStringValue.filter(a =>
                      poCondi.SelOp.some(x => a.value === x.fund_no)
                    )}
                    isMulti
                    options={fundCodeStringValue}
                    maxMenuHeight={150}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Tag Fund"
                    onChange={UPDxOnChgSelect(poCondi.TypeCodi)}
                  />
                }
              </div>
            </div>

            <div className="labelInput" style={{ paddingBottom: 10 }}>
              <span
                style={{
                  paddingRight: 171,
                  fontFamily: "Lato",
                  color: "#000000"
                }}
              >
                Status
              </span>
              <input
                name={poCondi.TypeCodi + "_AND"}
                type="radio"
                value="AND"
                defaultChecked={poCondi.Line2 === "AND"}
                onChange={UPDxOnChgRadio(ptIndex, poCondi.TypeCodi)}
              />{" "}
              <span style={{ marginRight: 20, marginLeft: 5 }}>
                ตกเงื่อนไขทั้งหมดตามที่ใส่มา (AND)
              </span>
              <input
                name={poCondi.TypeCodi + "_OR"}
                type="radio"
                value="OR"
                defaultChecked={poCondi.Line2 === "OR"}
                onChange={UPDxOnChgRadio(ptIndex, poCondi.TypeCodi)}
              />{" "}
              <span style={{ marginLeft: 5 }}>
                ตกเงื่อนไขกองใดกองหนึ่ง (OR)
              </span>
            </div>
          </div>
        );
      case "C004":
        return (
          <div>
            <div
              className="labelInput"
              style={{ paddingTop: 20, paddingBottom: 20 }}
            >
              <span
                style={{ width: 204, fontFamily: "Lato", color: "#000000" }}
              >
                กลุ่มอาชีพ
              </span>
              <div style={{ width: 599 }}>
                {
                  <Select
                    defaultValue={oOccupaOp.filter(a =>
                      poCondi.SelOp.some(x => a.value === x.code)
                    )}
                    isMulti
                    options={oOccupaOp}
                    maxMenuHeight={150}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Tag Fund"
                    onChange={UPDxOnChgSelect(poCondi.TypeCodi)}
                  />
                }
              </div>
            </div>
          </div>
        );
      case "C005":
        return (
          <div>
            <div
              className="labelInput"
              style={{ paddingTop: 20, paddingBottom: 20 }}
            >
              <span
                style={{ width: 204, fontFamily: "Lato", color: "#000000" }}
              >
                ช่วงรายได้
              </span>
              <div style={{ width: 599 }}>
                {
                  <Select
                    defaultValue={oIncomeOp.filter(a =>
                      poCondi.SelOp.some(x => a.value === x.code)
                    )}
                    isMulti
                    options={oIncomeOp}
                    maxMenuHeight={150}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Tag Fund"
                    onChange={UPDxOnChgSelect(poCondi.TypeCodi)}
                  />
                }
              </div>
            </div>
          </div>
        );
      case "C006":
        return (
          <div>
            <div
              className="labelInput"
              style={{ paddingTop: 20, paddingBottom: 20 }}
            >
              <span
                style={{ width: 204, fontFamily: "Lato", color: "#000000" }}
              >
                ช่วงความเสี่ยง
              </span>
              <div style={{ width: 599 }}>
                {
                  <Select
                    defaultValue={oRiskOp.filter(a =>
                      poCondi.SelOp.some(x => a.value === x.code)
                    )}
                    isMulti
                    options={oRiskOp}
                    maxMenuHeight={150}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Tag Fund"
                    onChange={UPDxOnChgSelect(poCondi.TypeCodi)}
                  />
                }
              </div>
            </div>
          </div>
        );
      case "C008":
        return (
          <div className="labelInput">
            <span style={{ width: 204, fontFamily: "Lato", color: "#000000" }}>
              ช่องจำนวนเงิน
            </span>
            <input
              className="fundrec-textInput-option"
              maxLength="20"
              value={poCondi.Range1}
              name="Range1"
              placeholder=""
              onKeyPress={e => {
                if (!/[0-9\b]+$/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              onChange={UPDxOnChgTxt(ptIndex)}
              style={{ width: 100 }}
            />
            <span style={{ marginLeft: 10, marginRight: 10 }}>-</span>
            <input
              className="fundrec-textInput-option"
              maxLength="20"
              value={poCondi.Range2}
              name="Range2"
              placeholder=""
              onKeyPress={e => {
                if (!/[0-9\b]+$/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              onChange={UPDxOnChgTxt(ptIndex)}
              style={{ width: 100 }}
            />
          </div>
        );
      case "C009":
        return (
          <div>
            <div
              className="labelInput"
              style={{ paddingTop: 20, paddingBottom: 20 }}
            >
              <span
                style={{ width: 204, fontFamily: "Lato", color: "#000000" }}
              >
                วัตถุประสงค์ในการลงทุน
              </span>
              <div style={{ width: 599 }}>
                {
                  <Select
                    defaultValue={oInvestObjOp.filter(a =>
                      poCondi.SelOp.some(x => a.value === x.code)
                    )}
                    isMulti
                    options={oInvestObjOp}
                    maxMenuHeight={150}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Tag Fund"
                    onChange={UPDxOnChgSelect(poCondi.TypeCodi)}
                  />
                }
              </div>
            </div>
          </div>
        );
      default:
        return <div />;
    }
  };

  const GENxSelectFunCode = () => {
    
    return (
      <div className="labelInput">
        <span
          style={{ paddingRight: 142, fontFamily: "Lato", color: "#000000" }}
        >
          Fund Code
        </span>
        <div style={{ width: 599 }}>
          <Select
            defaultValue={
              aCodiFundRec
                ? oFunCodeOp.filter(a =>
                    aCodiFundRec.some(x => a.value === x.fund_no)
                  )
                : []
            }
            isMulti
            name="ocbTagFundCodeXX"
            options={oFunCodeOp}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Select Fund Code"
            maxMenuHeight={215}
            onChange={UPDxFunCodeOnChgSelect}
            value={
              oSelFundCodeMainOp === null ? oFunTagFltInit : oSelFundCodeMainOp
            }
          />
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <div className="fundrec-feed-form-container">
        <div className="fundrec-feed-form-buttons-container">
          <button
            className="fundrec-detail-cancel"
            onClick={() => history.goBack()}
          >
            CANCEL
          </button>
          <button
            type="submit"
            onClick={() => SETxShwEdtAlr(true)}
            className={
              oVal.otbRuleName1 === "" || aCondition.length === 0
                ? "content-feed-form-publish-button-disabled"
                : "content-feed-form-publish-button"
            }
            disabled={
              oVal.otbRuleName1 === "" || aCondition.length === 0 ? true : false
            }
          >
            SAVE
          </button>
          <WarningModal
            show={oShwEdtAlr}
            onHide={() => SETxShwEdtAlr(false)}
            onHandleGo={() => {
              SETxShwEdtAlr(false);

              let oSendDataAPI = {
                CodiName: oVal.otbRuleName1,
                Status: oSta,
                UserSta: oUsr,
                ModifyBy: oNeedAuth?.Email,
                Rule: aCondition === null ? [] : aCondition,
                CodiFundRec: aCodiFundRec === null ? [] : aCodiFundRec
              };
              
              REQByCodiCrt({
                FormData: oSendDataAPI,
                history
              });
              console.log("Sent data (create)", oSendDataAPI)
            }}
            onHandleCancel={() => {
              SETxShwEdtAlr(false);
            }}
            type={"Disapprove"}
            status={"1"}
            title={"Are you sure you want to edit?"}
          />
        </div>

        <div
          className="fundrec-feed-form-inputs-containerRisk"
          style={{ minHeight: 540 }}
        >
          {/*------------------------Input Card---------------------------*/}
          <div style={{ paddingRight: 40 }}>
            <div className="fundrec-feed-form-input-spacer" />
            <div className="labelInput">
              <span
                style={{
                  paddingRight: 142,
                  fontFamily: "Lato",
                  color: "#000000"
                }}
              >
                Rule Name
              </span>
              <input
                className="textInput"
                placeholder="Rule Name"
                value={oVal.otbRuleName1}
                onChange={SETxOnChgTxt("otbRuleName1")}
              />
            </div>

            <div className="fundrec-feed-form-input-spacer" />
            {<GENxSelectFunCode />}
            <div className="fundrec-feed-form-input-spacer" />

            <div className="labelInput">
              <span
                style={{
                  paddingRight: 171,
                  fontFamily: "Lato",
                  color: "#000000"
                }}
              >
                Status
              </span>
              <input
                name="orbSta1"
                type="radio"
                value="Unpublish"
                checked={oSta === "Unpublish"}
                onChange={SETxOnChgRaioAdd}
              />
              <span style={{ marginRight: 20, marginLeft: 5 }}>Unpublish</span>
              <input
                name="orbSta1"
                type="radio"
                value="Publish"
                checked={oSta === "Publish"}
                onChange={SETxOnChgRaioAdd}
              />
              <span style={{ marginLeft: 5 }}>Publish</span>
            </div>

            <div style={{ marginTop: 20 }}>
              <table border="0" className="conFd-feed-form-table">
                <tbody>
                  <tr height={30}>
                    <td rowSpan="2" width={211} style={{ paddingBottom: 28 }}>
                      User
                    </td>
                    <td>
                      <input
                        name="orbUsr1"
                        type="radio"
                        value="All"
                        checked={oUsr === "All"}
                        onChange={SETxOnChgRaioAdd}
                      />
                      <span style={{ marginLeft: 5, marginRight: 20 }}>
                        แสดงทุกกลุ่ม
                      </span>
                      <input
                        name="orbUsr1"
                        type="radio"
                        value="PVD"
                        checked={oUsr === "PVD"}
                        onChange={SETxOnChgRaioAdd}
                      />
                      <span style={{ marginLeft: 5, marginRight: 20 }}>
                        เฉพาะลูกค้า PVD
                      </span>
                      <input
                        name="orbUsr1"
                        type="radio"
                        value="MutualFund"
                        checked={oUsr === "MutualFund"}
                        onChange={SETxOnChgRaioAdd}
                      />{" "}
                      เฉพาะลูกค้า MUTUAL FUND
                    </td>
                  </tr>
                  <tr height={30}>
                    <td>
                      <input
                        name="orbUsr1"
                        type="radio"
                        value="SoftOnboard"
                        checked={oUsr === "SoftOnboard"}
                        onChange={SETxOnChgRaioAdd}
                      />{" "}
                      SOFT ONBOARD <span style={{ marginRight: 20 }} />
                      <input
                        name="orbUsr1"
                        type="radio"
                        value="PVDAndMutualFund"
                        checked={oUsr === "PVDAndMutualFund"}
                        onChange={SETxOnChgRaioAdd}
                      />{" "}
                      เฉพาะลูกค้าที่มี PVD และ MUTUAL FUND
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="fundrec-feed-form-input-spacer" />
          </div>
          {/*------------------GEN card--------------------------*/}
          {aCondition.length
            ? aCondition.map((x, index) => (
                <div
                  key={x.TypeCodi}
                  style={{
                    backgroundColor: "#EDEDED",
                    marginTop: 20,
                    marginBottom: 20
                  }}
                >
                  <button
                    className="fundrec-feed-form-delete-button"
                    onClick={DELxCodi(x.TypeCodi)}
                  >
                    <img
                      src={Images.closeButton.close}
                      style={{ marginBottom: 3, width: 18, height: 18 }}
                      alt="close"
                    />
                  </button>
                  <div
                    style={{
                      paddingTop: 15,
                      paddingLeft: 40,
                      paddingRight: 40
                    }}
                  >
                    <div className="labelInput">
                      <span
                        style={{
                          paddingRight: 142,
                          fontFamily: "Lato",
                          color: "#000000"
                        }}
                      >
                        Condition
                      </span>
                      <div style={{ width: 599 }}>
                        <Select
                          name="ocbFunRecCodiCode1111"
                          defaultValue={[
                            {
                              value: x.TypeCodi,
                              label: FINDxNameCombobox(x.TypeCodi)
                            }
                          ]}
                          options={[
                            {
                              value: x.TypeCodi,
                              label: FINDxNameCombobox(x.TypeCodi)
                            }
                          ]}
                          maxMenuHeight={150}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          isDisabled={true}
                        />
                      </div>
                    </div>

                    <div style={{ paddingTop: 20, paddingBottom: 10 }}>
                      {GENxCodi(x, index)}
                    </div>
                  </div>
                </div>
              ))
            : null}

          {/*------------------GEN Select--------------------------*/}
          <div
            style={{
              backgroundColor: "#EDEDED",
              paddingLeft: 40,
              paddingRight: 40,
              paddingBottom: 20,
              paddingTop: 20
            }}
          >
            <div className="labelInput">
              <span
                style={{
                  paddingRight: 142,
                  fontFamily: "Lato",
                  color: "#000000"
                }}
              >
                Condition
              </span>
              <div style={{ width: 599 }}>
                <Select
                  name="ocbFunRecCodiCode1"
                  options={aRecCodiCodeOp}
                  maxMenuHeight={150}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Select Condition"
                  // assign onChange function
                  onChange={SETxOnChgFundCodiSelect}
                  value={tSelFundRecCodiCodeOp}
                />
              </div>
            </div>
            {/*------------------When select Condi option--------------------------*/}
            <div>
              <GENxSelect />
            </div>
          </div>

          <button
            style={{
              marginTop: 20,
              fontSize: 12,
              width: 110,
              height: 40,
              paddingTop: 4
            }}
            className="fundrec-detail-cancel"
            onClick={CRTxCodiAdd}
          >
            <img
              src={Images.buttonIcon.plus}
              style={{
                marginBottom: 3,
                marginRight: 5,
                width: 16,
                height: 16
              }}
              alt="delete"
            />
            <span>ADD RULE</span>
          </button>
        </div>
      </div>
    </Fragment>
  );
}

const withConnect = connect(
  state => ({
    ...state.fundRecCodiDetailReducer
  }),
  {
    REQFundCode,
    REQRisk,
    REQOccu,
    REQIncome,
    REQByCodiCrt
  }
);

export default compose(
  withConnect,
  withRouter
)(FundRecCodiCrt);
